import { cn } from "@/lib/utils";
// import { Footer } from "@/components/sidebar/footer";
import { Sidebar } from "@/components/sidebar/sidebar";
import { useSidebarToggle } from "@/stores/useSidebarToggle";

export default function AdminPanelLayout({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) {
    const sidebar = useSidebarToggle();

    if (!sidebar) return null;

    // main had: bg-zinc-50 
    return (
        <>
            <Sidebar />
            <main 
                className={cn(
                    "min-h-[calc(100vh_-_56px)] dark:bg-zinc-900 transition-[margin-left] ease-in-out duration-300",
                    sidebar?.isOpen === false ? "lg:ml-[90px]" : "lg:ml-72",
                )}
            >
                {children}
            </main>
            <footer
                className={cn(
                    "transition-[margin-left] ease-in-out duration-300",
                    sidebar?.isOpen === false ? "lg:ml-[90px]" : "lg:ml-72",
                )}
            >
                {/* <Footer /> */}
            </footer>
        </>
    );
}
