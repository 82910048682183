import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/(protected)/_auth/courses/')({
	component: DashboardPage,
});

function DashboardPage() {
	return (
		<div>
			<h1 className="text-xl font-semibold">🎈 Welcome</h1>
			<p className="mb-5">
				Please select a course from the sidebar to start adding content.
			</p>
			<i>Powered by {'ELT Global'}</i>
		</div>
	);
}
