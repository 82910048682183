import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useCoursesControllerFindOne } from '@/api/query/courses/courses';
import { Link } from '@tanstack/react-router';
import { useUnitsControllerFindAll } from '@/api/query/units/units';
import { LucideChevronDown } from 'lucide-react';
import { useInsidebarToggle } from '@/stores/useInsidebarToggle';

export function NavbarBreadcrumb({ courseId, unitId }: { courseId?: string, unitId?: string }) {

	const { data: courseData } = useCoursesControllerFindOne(courseId ?? '');
	const { data } = useUnitsControllerFindAll({ parentCourse: courseId! });
	const currentSubtopic = data?.units?.find((e) => e.id === unitId);
	const currentTopic = data?.units?.find(
		(e) => e.id === currentSubtopic?.parentUnit
	);
	const currentChapter = data?.units?.find(
		(e) => e.id === currentTopic?.parentUnit
	);
	const currentPaper = data?.units?.find(
		(e) => e.id === currentChapter?.parentUnit
	);

	const { setUnitTree } = useInsidebarToggle();

	return (
		<Breadcrumb className="my-2">
			<BreadcrumbList>
				<BreadcrumbItem>
					<BreadcrumbLink href="/">Home</BreadcrumbLink>
				</BreadcrumbItem>
				<BreadcrumbSeparator />
				<BreadcrumbItem>
					<Link
						to="/courses/$courseId"
						params={{ courseId: courseId! }}
						className="truncate w-max-24"
					>
						{/* <BreadcrumbLink className="truncate w-max-24"> */}
							{courseData
								? `${courseData?.name} ${courseData?.stage}`
								: courseId}
						{/* </BreadcrumbLink> */}
					</Link>
				</BreadcrumbItem>
				<BreadcrumbSeparator />
				{currentPaper && (
					<>
						<BreadcrumbItem className="cursor-pointer" onClick={() => setUnitTree({paperId: currentPaper.id})}>
							<BreadcrumbLink className="w-max-[20ch] truncate">
								{currentPaper?.title ?? 'Paper'}
							</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
					</>
				)}
				{currentPaper && currentChapter && (
					<>
						<BreadcrumbItem className="cursor-pointer" onClick={() => setUnitTree({paperId: currentPaper.id, chapterId: currentChapter.id})}>
							<BreadcrumbLink className="w-max-[20ch] truncate">
								{currentChapter?.title ?? 'Chapter'}
							</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
					</>
				)}

				{currentPaper && currentChapter && currentTopic && (
					<>
						<BreadcrumbItem className="cursor-pointer" onClick={() => setUnitTree({paperId: currentPaper.id, chapterId: currentChapter.id, topicId: currentTopic.id})}>
							<BreadcrumbLink className="w-max-[20ch] truncate">
								{currentTopic?.title ?? 'Topic'}
							</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
					</>
				)}
				{currentSubtopic && (
					<BreadcrumbItem>
						<DropdownMenu>
							<DropdownMenuTrigger className="flex items-center gap-1">
								<BreadcrumbPage>
									{data
										? data.units.find(
												(e) => e.id === unitId
											)?.title ?? courseId
										: courseId}
								</BreadcrumbPage>
								<LucideChevronDown className="mx-2 h-4 w-4" />
								<span className="sr-only">Toggle menu</span>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="start">
								{data?.units
									.filter((e) => e.type === 'subtopic' && e.parentUnit === currentTopic?.id)
									.map((unit) => (
										<Link
											key={unit.id}
											to={'/courses/$courseId/$unitId'}
											params={{
												unitId: unit.id,
												courseId: courseId!,
											}}
										>
											<DropdownMenuItem>
												{unit.title}
											</DropdownMenuItem>
										</Link>
									))}
							</DropdownMenuContent>
						</DropdownMenu>
					</BreadcrumbItem>
				)}
			</BreadcrumbList>
		</Breadcrumb>
	);
}
