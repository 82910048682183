import { useUnitsControllerFindAll } from '@/api/query/units/units';
import { useInsidebarToggle } from '@/stores/useInsidebarToggle';
import { useEffect, useState } from 'react';
import { SidebarElement } from './SidebarElement';
import { useNavigate, useParams, useRouterState } from '@tanstack/react-router';

export function Sidebar() {
	const router = useRouterState();
	const { courseId } = useParams({ strict: false });
	const navigate = useNavigate({ from: '/courses/$courseId' });

	const { activePaper, setActivePaper, unitTree, setUnitTree } = useInsidebarToggle();
	const [activeChapter, setActiveChapter] = useState<string>('');
	const [activeTopic, setActiveTopic] = useState<string>('');
	const [activeSubtopic, setActiveSubtopic] = useState<string>('');

	// Close the insidebar on navigation
	useEffect(() => {
		setActivePaper('');
		setUnitTree({ paperId: ''})
		// queryClient.invalidateQueries({ queryKey: getUnitsControllerFindAllQueryKey() })
	}, [router.location.pathname, setActivePaper, setUnitTree]);

	useEffect(() => {
		if (unitTree.chapterId) {
			setActiveChapter(unitTree.chapterId);
		} else {
			setActiveChapter('');
		}
	}, [activePaper, unitTree.chapterId]);

	useEffect(() => {
		if (unitTree.topicId) {
			setActiveTopic(unitTree.topicId);
		} else {
			setActiveTopic('');
		}
	}, [activeChapter, unitTree.topicId]);

	useEffect(() => {
		setActiveSubtopic('');
	}, [activeTopic]);

	useEffect(() => {
		if (unitTree.paperId) {
			setActivePaper(unitTree.paperId);
		}
	}, [unitTree, setActivePaper])

	// We trigger a route navigation when a subtopic is selecte.
	useEffect(() => {
		if (activeSubtopic) {
			console.log(
				`Should ideally navigate to /courses/${courseId}/${activeSubtopic}`
			);
			navigate({
				to: '/courses/$courseId/$unitId',
				params: { courseId: courseId, unitId: activeSubtopic },
			});
		}
	}, [activeSubtopic, setActivePaper, navigate, courseId]);

	function closeSidebar() {
		setActivePaper('');
		setUnitTree({ paperId: ''})
	}

	const { data } = useUnitsControllerFindAll({
		parentCourse: courseId!,
	});

	return (
		<aside className="fixed bg-slate-600 top-0 z-30 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300">
			<div className={"z-20 relative h-full flex bg-white overflow-y-auto pr-2  dark:shadow-zinc-800 transition-[width] ease-in-out duration-300"}>
				{/* Chapters Sidebar */}
				{activePaper && (
					<SidebarElement
						title="Chapters"
						data={data?.units.filter(
							(e) => e.parentUnit === activePaper
						)}
						activeElementUnitId={activeChapter}
						setActiveElementUnitId={setActiveChapter}
						unitType="chapter"
						parentCourse={courseId ?? ''}
						parentUnit={activePaper}
						parentUnits={data?.units.filter(e => e.type === "paper").map(e => ({ title: e.title, id: e.id }))}
					/>
				)}
				{/* Topics Extended Sidebar */}
				{activeChapter && (
					<SidebarElement
						title="Topics"
						data={data?.units.filter(
							(e) => e.parentUnit === activeChapter
						)}
						activeElementUnitId={activeTopic}
						setActiveElementUnitId={setActiveTopic}
						unitType="topic"
						parentCourse={courseId ?? ''}
						parentUnit={activeChapter}
						parentUnits={data?.units.filter(e => e.type === "chapter" && e.parentUnit === activePaper).map(e => ({ title: e.title, id: e.id }))}
					/>
				)}
				{/* Subtopics Super Extended Sidebar */}
				{activeTopic && (
					<SidebarElement
						title="Subtopics"
						data={data?.units.filter(
							(e) => e.parentUnit === activeTopic
						)}
						activeElementUnitId={activeSubtopic}
						setActiveElementUnitId={setActiveSubtopic}
						unitType="subtopic"
						parentCourse={courseId ?? ''}
						parentUnit={activeTopic}
						parentUnits={data?.units.filter(e => e.type === "topic" && e.parentUnit === activeChapter).map(e => ({ title: e.title, id: e.id }))}
					/>
				)}
			</div>
			{activePaper && (
				<div
					role="none"
					className="fixed backdrop-blur-sm bg-neutral-900/10 top-0 left-0 w-screen h-screen"
					onClick={closeSidebar}
				/>
			)}
		</aside>
	);
}
