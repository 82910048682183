import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';

import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from './components/ui/toaster';
import { AlertDialogProvider } from './components/ui/alert-dialog-provider';
import AuthProvider from './contexts/AuthContext';
import { AuthContext } from './contexts/AuthContextUtils';
// import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import * as Sentry from '@sentry/react';
import { ENV } from './lib/constants';
import { TooltipProvider } from './components/ui/tooltip';

if (import.meta.env.PROD && ENV.SENTRY_DSN) {
	Sentry.init({
		dsn: ENV.SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			// Sentry.tanstackRouterBrowserTracingIntegration(router)
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', `https://api.tripleica.com`],
		// Session Replay
		replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
	console.log('🔰 Sentry enabled.');
} else {
	console.log('🔰 Sentry disabled.');
}

const queryClient = new QueryClient();

const router = createRouter({
	routeTree,
	context: {
		auth: undefined!,
		queryClient,
	},
	defaultPreloadStaleTime: 0,
	defaultPreload: 'intent',
});

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<React.StrictMode>
			<AuthProvider>
				<Root />
			</AuthProvider>
		</React.StrictMode>
	);
}

export function Root() {
	const authContext = useContext(AuthContext);

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<AlertDialogProvider>
					<TooltipProvider>
						<RouterProvider router={router} context={{ auth: authContext }} />
					</TooltipProvider>
					{/* <TanStackRouterDevtools router={router} /> */}
				</AlertDialogProvider>
			</QueryClientProvider>
			<Toaster />
		</>
	);
}
