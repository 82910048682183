import { Link, LinkProps, useNavigate, useRouter, useRouterState } from "@tanstack/react-router";
import { BookIcon, Ellipsis, LogOut } from "lucide-react";
import { cn } from "@/lib/utils";
import { getMenuList } from "@/lib/menu-list";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "@/components/ui/tooltip";
import { CollapseMenuButton } from "./collapse-menu-button";
import { useCoursesControllerFindAll } from "@/api/query/courses/courses";
import { Course } from "@/api/query/model";
import { useContext } from "react";
import { AuthContext } from "@/contexts/AuthContextUtils";

interface Submenu {
    to: LinkProps;
    label: string;
    active: boolean;
}

interface Menu {
    to: '';
    label: string;
    active: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
    submenus: Submenu[];
}

interface Group {
    groupLabel: string;
    menus: Menu[];
}

interface MenuProps {
    isOpen: boolean | undefined;
}

export function Menu({ isOpen }: Readonly<MenuProps>) {
    const router = useRouterState();
    const menuList = getMenuList(router.location.pathname);
	const { data } = useCoursesControllerFindAll();

	const { invalidate } = useRouter();
	const navigate = useNavigate();

	const authContext = useContext(AuthContext);
	async function logMeOut() {
		authContext?.logout();
		await invalidate();
		navigate({to: "/"});
	}

	// We need to group the courses by their course type first (CA, ACCA, etc.)
	const courses = new Map<string, Course[]>();
	for (const course of data?.courses ?? []) {
		if (!courses.has(course.name)) {
			courses.set(course.name, []);
		}

		courses.get(course.name)?.push(course);
	}

	const group: Group = {
		groupLabel: "Courses",
		menus: []
	};

	for (const [courseName, courseObject] of courses) {
		group.menus.push({
			label: courseName,
			to: '',
			active: router.location.pathname.includes(`/courses/${courseName.toLowerCase()}`),
			icon: BookIcon,
			submenus: courseObject.map(subObject => ({
				to: {to: "/courses/$courseId", params: {courseId: subObject.id}},
				label: subObject.stage,
				active: router.location.pathname.includes(`/courses/${subObject.id}`)
			}))
		})
	}

	menuList.splice(1, 0, group);

    return (
        <ScrollArea className="[&>div>div[style]]:!block">
            <nav className="mt-8 h-full w-full">
                <ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px)] lg:min-h-[calc(100vh-32px-40px-32px)] items-start space-y-1 px-2">
                    {menuList.map(({ groupLabel, menus }, index) => (
                        <li className={cn("w-full", groupLabel ? "pt-5" : "")} key={index}>
                            {(isOpen && groupLabel) ?? isOpen === undefined ? (
                                <p className="text-sm font-medium text-muted-foreground px-4 pb-2 max-w-[248px] truncate">
                                    {groupLabel}
                                </p>
                            ) : !isOpen && isOpen !== undefined && groupLabel ? (
                                <TooltipProvider>
                                    <Tooltip delayDuration={100}>
                                        <TooltipTrigger className="w-full">
                                            <div className="w-full flex justify-center items-center">
                                                <Ellipsis className="h-5 w-5" />
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent side="right">
                                            <p>{groupLabel}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            ) : (
                                <p className="pb-2"></p>
                            )}
                            {menus.map(({ to, label, icon: Icon, active, submenus }, index) =>
                                submenus.length === 0 ? (
                                    <div className="w-full" key={index}>
                                        <TooltipProvider disableHoverableContent>
                                            <Tooltip delayDuration={100}>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        variant={active ? "secondary" : "ghost"}
                                                        className="w-full justify-start h-10 mb-1"
                                                        asChild
                                                    >
                                                        <Link to={to}>
                                                            <span className={cn(isOpen === false ? "" : "mr-4")}>
                                                                <Icon className="text-blue-600" size={18} />
                                                            </span>
                                                            <p
                                                                className={cn(
                                                                    "max-w-[200px] truncate",
                                                                    isOpen === false
                                                                        ? "-translate-x-96 opacity-0"
                                                                        : "translate-x-0 opacity-100",
                                                                )}
                                                            >
                                                                {label}
                                                            </p>
                                                        </Link>
                                                    </Button>
                                                </TooltipTrigger>
                                                {isOpen === false && (
                                                    <TooltipContent side="right">{label}</TooltipContent>
                                                )}
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                ) : (
                                    <div className="w-full" key={index}>
                                        <CollapseMenuButton
                                            icon={Icon}
                                            label={label}
                                            active={active}
                                            submenus={submenus}
                                            isOpen={isOpen}
                                        />
                                    </div>
                                ),
                            )}
                        </li>
                    ))}
                    <li className="w-full grow flex items-end">
                        <TooltipProvider disableHoverableContent>
                            <Tooltip delayDuration={100}>
                                <TooltipTrigger asChild>
                                    <Button
                                        variant="outline"
										onClick={logMeOut}
                                        className="w-full justify-center h-10 mt-5"
                                    >
                                        <span className={cn(isOpen === false ? "" : "mr-4")}>
                                            <LogOut size={18} />
                                        </span>
                                        <p
                                            className={cn(
                                                "whitespace-nowrap",
                                                isOpen === false ? "opacity-0 hidden" : "opacity-100",
                                            )}
                                        >
                                            Sign out
                                        </p>
                                    </Button>
                                </TooltipTrigger>
                                {isOpen === false && <TooltipContent side="right">Sign out</TooltipContent>}
                            </Tooltip>
                        </TooltipProvider>
                    </li>
                </ul>
            </nav>
        </ScrollArea>
    );
}
