import { UnitType } from '@/api/query/model';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function capitalize(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function unitTypeFromSlug(slug?: string): UnitType {
	console.log(slug, 'was given');
	if (!slug) throw new Error("Slug can't be empty");
	// e.g: ca_fou_p1J4y
	//             ^ this part identifies the type.
	const tokens = slug?.split('_');
	const lastPart = tokens[tokens.length - 1];
	const char = lastPart.charAt(0);
	switch (char) {
		case 'p':
			return 'paper';
		case 'c':
			return 'chapter';
		case 't':
			return 'topic';
		case 's':
			return 'subtopic';
		default:
			throw new Error(`Unknown unit type: ${char}`);
	}
}

export function getErrorMessage(error: unknown) {
	if (error instanceof Error) {
		return error.message;
	}
	if (typeof error === 'string') {
		return error;
	}
	return 'An error occurred';
}

export function handleKeyDown(event: React.KeyboardEvent, onClick: () => void): void {
	if (event.key === 'Enter' || event.key === ' ') {
		event.preventDefault();
		onClick();
	}
}
