import { Unit } from '@/api/query/model';
import {
	useUnitsControllerRemove,
} from '@/api/query/units/units';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import StateButton from '@/components/ui/state-button';
import { useToast } from '@/components/ui/use-toast';
import { getErrorMessage } from '@/lib/utils';
import React, { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { LucideEye, LucideUserRoundCheck } from 'lucide-react';
import { z } from 'zod';
import { Input } from '@/components/ui/input';
import { AccessItem } from './access-item';

interface AssignUnitDialogProps {
	unit: Unit;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

const EditUnitDialogSchema = z.object({
	title: z.string().min(1, 'Unit name is required.'),
	parentUnit: z.string().min(1, 'Parent unit is required.'),
	// tags: z.array(z.string()),
	// isEnd: z.boolean(),
});

const DeleteUnitDialogSchema = z.object({
	consequencesCheckbox: z.boolean(),
});

const AssignUnitDialog = ({ isOpen, setIsOpen, unit }: AssignUnitDialogProps) => {
	const { toast } = useToast();

	/* Mutations */
	const { mutate: deleteUnit, isPending: isDeletionPending } = useUnitsControllerRemove();

	/** Whether the user wishes to delete the unit or edit it. */
	const [seeActivity, setSeeActivity] = useState(false);

	/** Form state for updation. */
	const [unitUpdationData, setUnitUpdationData] = useState<z.infer<typeof EditUnitDialogSchema>>(() => ({
		title: unit.title,
		parentUnit: unit.parentUnit!,
	}));

	/** Form state for deletion. */
	const [unitDeletionData, setUnitDeletionData] = useState<z.infer<typeof DeleteUnitDialogSchema>>(() => ({
		/* Do you really really want to delete this unit? */
		consequencesCheckbox: false,
	}));


	/** Reset the form state when the unit changes. */
	useEffect(() => {
		setUnitUpdationData({
			title: unit.title,
			parentUnit: unit.parentUnit!,

		});

		setUnitDeletionData({
			consequencesCheckbox: false,
		});

		setSeeActivity(false);
	}, [unit]);


	const removeUnit = () =>
		new Promise((resolve, reject) => {
			deleteUnit(
				{
					id: unit.id,
				},
				{
					onSuccess: (response) => {
						resolve(response);
					},
					onError: (error) => {
						const errorMessage = error.response?.data.response ?? 'Could not parse the error.';
						reject(new Error(errorMessage));
					},
				}
			);
		});

	async function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
		e.preventDefault();

		const validate = seeActivity
			? await DeleteUnitDialogSchema.safeParseAsync(unitDeletionData)
			: await EditUnitDialogSchema.safeParseAsync(unitUpdationData);
		if (!validate.success) {
			toast({
				title: 'Invalid form data.',
				description: validate.error.errors.map((error) => error.message).join('\n'),
				variant: 'destructive',
			});
			return;
		}

		try {
			await removeUnit();
			toast({
				title: 'Unit Deleted',
				description: `'${unit.title}' was deleted successfully.`,
				variant: 'success',
			});
		} catch (err) {
			toast({
				title: 'Could not delete the unit.',
				description: getErrorMessage(err),
				variant: 'destructive',
			});
		}
	}

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Assign access to {`'${unit.title}'`}</DialogTitle>
					<DialogDescription>Control who has what access to this {unit.type}.</DialogDescription>
					<form onSubmit={handleSubmit}>
					<div className="my-2">
							<Label htmlFor="assignee" className="sr-only">Assign To:</Label>
							<Input type="text" name="assignee" id="assignee" placeholder={`Add people with access to this ${unit.type}.`} />
						</div>
						<h1 className="font-semibold">People with access</h1>
						<div className="overflow-auto max-h-72 mt-2 pb-5 border-y-slate-200 border-y-2">
							<AccessItem name="John Doe" createdAt={new Date("2024-07-13T10:11:17.171Z")} />
							<AccessItem name="Jane Doe" createdAt={new Date("2023-07-13T10:11:17.171Z")} />
						</div>

						<div className="float-right mt-5 gap-2 flex items-center justify-center">
							<Button type="button" variant="outline">
								<LucideEye className="mr-2 h-4 w-4" /> Activity
							</Button>
							<StateButton
								type="submit"
								isLoading={isDeletionPending}
								isDisabled={isDeletionPending} // || (shouldDelete ? !consequencesCheckbox : false)
								variant="iii"
							>
								<LucideUserRoundCheck className="mr-2 h-4 w-4" /> Done
							</StateButton>
						</div>
					</form>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default AssignUnitDialog;
