/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * API Documentation
 * Triple i Curate API Documentation
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type { FindAllUsersResponseDto, FindUserResponseDto, InternalServerError, UnauthorizedError } from '.././model';
import { customInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get the data of authenticated user
 */
export const usersControllerGetUser = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<FindUserResponseDto>({ url: `/api/v1/users/me`, method: 'GET', signal }, options);
};

export const getUsersControllerGetUserQueryKey = () => {
	return [`/api/v1/users/me`] as const;
};

export const getUsersControllerGetUserQueryOptions = <
	TData = Awaited<ReturnType<typeof usersControllerGetUser>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetUser>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUsersControllerGetUserQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerGetUser>>> = ({ signal }) =>
		usersControllerGetUser(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof usersControllerGetUser>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type UsersControllerGetUserQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerGetUser>>>;
export type UsersControllerGetUserQueryError = ErrorType<UnauthorizedError | InternalServerError>;

/**
 * @summary Get the data of authenticated user
 */
export const useUsersControllerGetUser = <
	TData = Awaited<ReturnType<typeof usersControllerGetUser>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetUser>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUsersControllerGetUserQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get a user by id
 */
export const usersControllerGetUserById = (
	userId: string,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal
) => {
	return customInstance<FindUserResponseDto>({ url: `/api/v1/users/${userId}`, method: 'GET', signal }, options);
};

export const getUsersControllerGetUserByIdQueryKey = (userId: string) => {
	return [`/api/v1/users/${userId}`] as const;
};

export const getUsersControllerGetUserByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof usersControllerGetUserById>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(
	userId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetUserById>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUsersControllerGetUserByIdQueryKey(userId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerGetUserById>>> = ({ signal }) =>
		usersControllerGetUserById(userId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof usersControllerGetUserById>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type UsersControllerGetUserByIdQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerGetUserById>>>;
export type UsersControllerGetUserByIdQueryError = ErrorType<UnauthorizedError | InternalServerError>;

/**
 * @summary Get a user by id
 */
export const useUsersControllerGetUserById = <
	TData = Awaited<ReturnType<typeof usersControllerGetUserById>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(
	userId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetUserById>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUsersControllerGetUserByIdQueryOptions(userId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get all users
 */
export const usersControllerGetAllUsers = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<FindAllUsersResponseDto>({ url: `/api/v1/users`, method: 'GET', signal }, options);
};

export const getUsersControllerGetAllUsersQueryKey = () => {
	return [`/api/v1/users`] as const;
};

export const getUsersControllerGetAllUsersQueryOptions = <
	TData = Awaited<ReturnType<typeof usersControllerGetAllUsers>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetAllUsers>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUsersControllerGetAllUsersQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerGetAllUsers>>> = ({ signal }) =>
		usersControllerGetAllUsers(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof usersControllerGetAllUsers>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type UsersControllerGetAllUsersQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerGetAllUsers>>>;
export type UsersControllerGetAllUsersQueryError = ErrorType<UnauthorizedError | InternalServerError>;

/**
 * @summary Get all users
 */
export const useUsersControllerGetAllUsers = <
	TData = Awaited<ReturnType<typeof usersControllerGetAllUsers>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersControllerGetAllUsers>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUsersControllerGetAllUsersQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
