import { Link } from '@tanstack/react-router'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Menu } from '@/components/sidebar/menu'
// import { useStore } from "@/hooks/use-store";
// import { useSidebarToggle } from "@/hooks/use-sidebar-toggle";
import { SidebarToggle } from '@/components/sidebar/sidebar-toggle'
import { useSidebarToggle } from '@/stores/useSidebarToggle'

export function Sidebar() {
	const sidebar = useSidebarToggle();

	if (!sidebar) return null

	return (
		<aside
			className={cn(
				'fixed top-0 left-0 z-40 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300',
				sidebar?.isOpen === false ? 'w-[90px]' : 'w-72'
			)}
		>
			<SidebarToggle
				isOpen={sidebar?.isOpen}
				setIsOpen={sidebar?.setIsOpen}
			/>
			<div className="relative h-full flex flex-col px-3 py-4 overflow-y-auto shadow-md dark:shadow-zinc-800">
				<Button
					className={cn(
						'transition-transform ease-in-out duration-300 mb-1 h-auto',
						sidebar?.isOpen === false
							? 'translate-x-1'
							: 'translate-x-0'
					)}
					variant="link"
					asChild
				>
					<Link to="/" className="flex items-center gap-2 my-2">
						{/* <PanelsTopLeft className="w-6 h-6 mr-1" /> */}
						<img
							src="/logo.png"
							className={cn(
								'font-bold text-lg whitespace-nowrap',
								sidebar?.isOpen === true
									? '-translate-x-96 opacity-0 hidden'
									: 'translate-x-0 opacity-100'
							)}
						/>
						<img
							src="/branding.png"
							className={cn(
								'w-[10rem] mr-1 rounded-md mt-4',
								sidebar?.isOpen === false
									? 'opacity-0 hidden'
									: ''
							)}
						/>
						{/* <h1
				className={cn(
					"font-bold text-lg whitespace-nowrap transition-[transform,opacity,display] ease-in-out duration-300",
					sidebar?.isOpen === false
					? "-translate-x-96 opacity-0 hidden"
					: "translate-x-0 opacity-100"
				)}
				>
				CRM
				</h1> */}
					</Link>
				</Button>
				<Menu isOpen={sidebar?.isOpen} />
			</div>
		</aside>
	)
}
