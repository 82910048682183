import { create } from 'zustand';

interface SidebarState {
	isOpen: boolean;
	setIsOpen: () => void;
	toggle: (state: boolean) => void;
}

export const useSidebarToggle = create<SidebarState>()((set, get) => ({
	isOpen: true,
	setIsOpen: () => {
		set({ isOpen: !get().isOpen });
	},
	toggle: (state: boolean) => {
		set({ isOpen: state });
	},
}));
