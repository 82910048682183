import { UnitType } from '@/api/query/model';
import {
	getUnitsControllerFindAllQueryKey,
	useUnitsControllerCreate,
} from '@/api/query/units/units';
// import { unitsControllerCreateBody } from '@/api/schemas/units/units';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import StateButton from '@/components/ui/state-button';
import { useToast } from '@/components/ui/use-toast';
import { capitalize } from '@/lib/utils';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
// import { z } from 'zod';

// We need to monkey-patch the zod schemas to add custom validation messages.
// unitsControllerCreateBody.shape.title = z.string().min(1, "Unit name is required.").optional();

interface CreateUnitDialogProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	unitType: UnitType;
	parentUnit?: string;
	parentCourse: string;
	setActiveElementUnitId?: (unitId: string) => void;
	// setUnitType: (unitType: UnitType) => void
}

const CreateUnitDialog = ({
	isOpen,
	setIsOpen,
	unitType,
	parentUnit,
	parentCourse,
	setActiveElementUnitId,
}: CreateUnitDialogProps) => {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	const { mutate, isPending: isCreationPending } = useUnitsControllerCreate();

	const [unitName, setUnitName] = useState('');

	function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
		e.preventDefault();
		if (!unitName) {
			return toast({
				title: 'Validation Failed',
				description: 'Unit name is required.',
				variant: 'destructive',
			});
		}

		console.table({
			title: unitName,
			type: unitType,
			parentUnit: parentUnit,
			parentCourse: parentCourse,
		});
		mutate(
			{
				data: {
					title: unitName,
					type: unitType,
					parentUnit: parentUnit,
					parentCourse: parentCourse,
				},
			},
			{
				onSuccess: (data) => {
					toast({
						title: 'Unit Created',
						description: `'${unitName}' was created successfully.`,
						variant: 'success',
					});
					setIsOpen(false);
					setUnitName('');
					// setUnitDescription('');
					queryClient.invalidateQueries({
						queryKey: getUnitsControllerFindAllQueryKey({ parentCourse: parentCourse }),
					});
					setActiveElementUnitId?.(data.unit.id);
				},
				onError: (error) => {
					const errorMessage = error.response?.data.response ?? "Could not parse the error.";
					toast({
						title: 'Could not create the unit.',
						description: errorMessage,
						variant: "destructive"
					})
				},
			}
		);
	}

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			{/* <DialogTrigger>Open</DialogTrigger> */}
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Create a {capitalize(unitType)}</DialogTitle>
					<DialogDescription>
						Create a new {unitType} for this course.
					</DialogDescription>
					<form onSubmit={handleSubmit}>
						<div>
							<Label htmlFor="unitName">
								{capitalize(unitType)} Name
							</Label>
							<Input
								value={unitName}
								onChange={(e) => setUnitName(e.target.value)}
								name="unitName"
								type="text"
								className="my-1"
								placeholder={`${capitalize(unitType)} 1`}
							/>
						</div>
						<StateButton
							type="submit"
							className="mt-5 float-right"
							isLoading={isCreationPending}
							isDisabled={isCreationPending}
							variant="iii"
						>
							Create {capitalize(unitType)}
						</StateButton>
					</form>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default CreateUnitDialog;
