// import { LinkProps } from "@tanstack/react-router";
import { LinkProps } from '@tanstack/react-router';
import { Users, Settings, LayoutGrid } from 'lucide-react';

interface Submenu {
	to: LinkProps;
	label: string;
	active: boolean;
}

interface Menu {
	to: string; // LinkProps["to"];
	label: string;
	active: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	icon: any;
	submenus: Submenu[];
}

interface Group {
	groupLabel: string;
	menus: Menu[];
}

export function getMenuList(pathname: string): Group[] {
	return [
		{
			groupLabel: '',
			menus: [
				{
					to: '/',
					label: 'Dashboard',
					active: pathname.includes('/dashboard'),
					icon: LayoutGrid,
					submenus: [],
				},
			],
		},
		{
			groupLabel: 'Settings',
			menus: [
				{
					to: '/users',
					label: 'Users',
					active: pathname.includes('/users'),
					icon: Users,
					submenus: [],
				},
				{
					to: '/account',
					label: 'Account',
					active: pathname.includes('/account'),
					icon: Settings,
					submenus: [],
				},
			],
		},
	];
}
