/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * API Documentation
 * Triple i Curate API Documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseMutationResult,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import type {
	Content,
	ContentControllerFindAllParams,
	ContentControllerUpdate200,
	CreateContentDto,
	CreateContentResponseDto,
	DeleteContentResponseDto,
	FindContentResponseDto,
	InternalServerError,
	NotFoundError,
	UnauthorizedError,
	UpdateContentDto,
} from '.././model';
import { customInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Create a new content
 */
export const contentControllerCreate = (
	createContentDto: BodyType<CreateContentDto>,
	options?: SecondParameter<typeof customInstance>
) => {
	return customInstance<CreateContentResponseDto>(
		{
			url: `/api/v1/contents`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: createContentDto,
		},
		options
	);
};

export const getContentControllerCreateMutationOptions = <
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contentControllerCreate>>,
		TError,
		{ data: BodyType<CreateContentDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof contentControllerCreate>>,
	TError,
	{ data: BodyType<CreateContentDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof contentControllerCreate>>,
		{ data: BodyType<CreateContentDto> }
	> = (props) => {
		const { data } = props ?? {};

		return contentControllerCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ContentControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof contentControllerCreate>>>;
export type ContentControllerCreateMutationBody = BodyType<CreateContentDto>;
export type ContentControllerCreateMutationError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>;

/**
 * @summary Create a new content
 */
export const useContentControllerCreate = <
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contentControllerCreate>>,
		TError,
		{ data: BodyType<CreateContentDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof contentControllerCreate>>,
	TError,
	{ data: BodyType<CreateContentDto> },
	TContext
> => {
	const mutationOptions = getContentControllerCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Gets the content based on query
 */
export const contentControllerFindAll = (
	params: ContentControllerFindAllParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal
) => {
	return customInstance<FindContentResponseDto>({ url: `/api/v1/contents`, method: 'GET', params, signal }, options);
};

export const getContentControllerFindAllQueryKey = (params: ContentControllerFindAllParams) => {
	return [`/api/v1/contents`, ...(params ? [params] : [])] as const;
};

export const getContentControllerFindAllQueryOptions = <
	TData = Awaited<ReturnType<typeof contentControllerFindAll>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(
	params: ContentControllerFindAllParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof contentControllerFindAll>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getContentControllerFindAllQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof contentControllerFindAll>>> = ({ signal }) =>
		contentControllerFindAll(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof contentControllerFindAll>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type ContentControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof contentControllerFindAll>>>;
export type ContentControllerFindAllQueryError = ErrorType<UnauthorizedError | InternalServerError>;

/**
 * @summary Gets the content based on query
 */
export const useContentControllerFindAll = <
	TData = Awaited<ReturnType<typeof contentControllerFindAll>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(
	params: ContentControllerFindAllParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof contentControllerFindAll>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getContentControllerFindAllQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Updates the content
 */
export const contentControllerUpdate = (
	id: string,
	updateContentDto: BodyType<UpdateContentDto>,
	options?: SecondParameter<typeof customInstance>
) => {
	return customInstance<ContentControllerUpdate200>(
		{
			url: `/api/v1/contents/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: updateContentDto,
		},
		options
	);
};

export const getContentControllerUpdateMutationOptions = <
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contentControllerUpdate>>,
		TError,
		{ id: string; data: BodyType<UpdateContentDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof contentControllerUpdate>>,
	TError,
	{ id: string; data: BodyType<UpdateContentDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof contentControllerUpdate>>,
		{ id: string; data: BodyType<UpdateContentDto> }
	> = (props) => {
		const { id, data } = props ?? {};

		return contentControllerUpdate(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ContentControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof contentControllerUpdate>>>;
export type ContentControllerUpdateMutationBody = BodyType<UpdateContentDto>;
export type ContentControllerUpdateMutationError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>;

/**
 * @summary Updates the content
 */
export const useContentControllerUpdate = <
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contentControllerUpdate>>,
		TError,
		{ id: string; data: BodyType<UpdateContentDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof contentControllerUpdate>>,
	TError,
	{ id: string; data: BodyType<UpdateContentDto> },
	TContext
> => {
	const mutationOptions = getContentControllerUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Gets the content with content id
 */
export const contentControllerFindOne = (
	id: string,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal
) => {
	return customInstance<Content>({ url: `/api/v1/contents/${id}`, method: 'GET', signal }, options);
};

export const getContentControllerFindOneQueryKey = (id: string) => {
	return [`/api/v1/contents/${id}`] as const;
};

export const getContentControllerFindOneQueryOptions = <
	TData = Awaited<ReturnType<typeof contentControllerFindOne>>,
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof contentControllerFindOne>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getContentControllerFindOneQueryKey(id);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof contentControllerFindOne>>> = ({ signal }) =>
		contentControllerFindOne(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof contentControllerFindOne>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type ContentControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof contentControllerFindOne>>>;
export type ContentControllerFindOneQueryError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>;

/**
 * @summary Gets the content with content id
 */
export const useContentControllerFindOne = <
	TData = Awaited<ReturnType<typeof contentControllerFindOne>>,
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof contentControllerFindOne>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getContentControllerFindOneQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Removes the content with content id
 */
export const contentControllerRemove = (id: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<DeleteContentResponseDto>({ url: `/api/v1/contents/${id}`, method: 'DELETE' }, options);
};

export const getContentControllerRemoveMutationOptions = <
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contentControllerRemove>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof contentControllerRemove>>, TError, { id: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentControllerRemove>>, { id: string }> = (
		props
	) => {
		const { id } = props ?? {};

		return contentControllerRemove(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ContentControllerRemoveMutationResult = NonNullable<Awaited<ReturnType<typeof contentControllerRemove>>>;

export type ContentControllerRemoveMutationError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>;

/**
 * @summary Removes the content with content id
 */
export const useContentControllerRemove = <
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof contentControllerRemove>>,
		TError,
		{ id: string },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof contentControllerRemove>>, TError, { id: string }, TContext> => {
	const mutationOptions = getContentControllerRemoveMutationOptions(options);

	return useMutation(mutationOptions);
};
