/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * API Documentation
 * Triple i Curate API Documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseMutationResult,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import type {
	BadRequestError,
	CreateUnitDto,
	CreateUnitResponseDto,
	DeleteUnitResponseDto,
	FindAllUnitsResponseDto,
	FindTagsResponseDto,
	InternalServerError,
	NotFoundError,
	UnauthorizedError,
	Unit,
	UnitsControllerFindAllParams,
	UpdateUnitDto,
	UpdateUnitResponseDto,
} from '.././model';
import { customInstance } from '../../axios-instance';
import type { ErrorType, BodyType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Find all units
 */
export const unitsControllerFindAll = (
	params: UnitsControllerFindAllParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal
) => {
	return customInstance<FindAllUnitsResponseDto>({ url: `/api/v1/units`, method: 'GET', params, signal }, options);
};

export const getUnitsControllerFindAllQueryKey = (params: UnitsControllerFindAllParams) => {
	return [`/api/v1/units`, ...(params ? [params] : [])] as const;
};

export const getUnitsControllerFindAllQueryOptions = <
	TData = Awaited<ReturnType<typeof unitsControllerFindAll>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(
	params: UnitsControllerFindAllParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof unitsControllerFindAll>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUnitsControllerFindAllQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof unitsControllerFindAll>>> = ({ signal }) =>
		unitsControllerFindAll(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof unitsControllerFindAll>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type UnitsControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof unitsControllerFindAll>>>;
export type UnitsControllerFindAllQueryError = ErrorType<UnauthorizedError | InternalServerError>;

/**
 * @summary Find all units
 */
export const useUnitsControllerFindAll = <
	TData = Awaited<ReturnType<typeof unitsControllerFindAll>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(
	params: UnitsControllerFindAllParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof unitsControllerFindAll>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUnitsControllerFindAllQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Create a new unit
 */
export const unitsControllerCreate = (
	createUnitDto: BodyType<CreateUnitDto>,
	options?: SecondParameter<typeof customInstance>
) => {
	return customInstance<CreateUnitResponseDto>(
		{ url: `/api/v1/units`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: createUnitDto },
		options
	);
};

export const getUnitsControllerCreateMutationOptions = <
	TError = ErrorType<BadRequestError | UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof unitsControllerCreate>>,
		TError,
		{ data: BodyType<CreateUnitDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof unitsControllerCreate>>,
	TError,
	{ data: BodyType<CreateUnitDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof unitsControllerCreate>>,
		{ data: BodyType<CreateUnitDto> }
	> = (props) => {
		const { data } = props ?? {};

		return unitsControllerCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UnitsControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof unitsControllerCreate>>>;
export type UnitsControllerCreateMutationBody = BodyType<CreateUnitDto>;
export type UnitsControllerCreateMutationError = ErrorType<
	BadRequestError | UnauthorizedError | NotFoundError | InternalServerError
>;

/**
 * @summary Create a new unit
 */
export const useUnitsControllerCreate = <
	TError = ErrorType<BadRequestError | UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof unitsControllerCreate>>,
		TError,
		{ data: BodyType<CreateUnitDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof unitsControllerCreate>>,
	TError,
	{ data: BodyType<CreateUnitDto> },
	TContext
> => {
	const mutationOptions = getUnitsControllerCreateMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Find a unit by ID
 */
export const unitsControllerFindOne = (
	id: string,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal
) => {
	return customInstance<Unit>({ url: `/api/v1/units/${id}`, method: 'GET', signal }, options);
};

export const getUnitsControllerFindOneQueryKey = (id: string) => {
	return [`/api/v1/units/${id}`] as const;
};

export const getUnitsControllerFindOneQueryOptions = <
	TData = Awaited<ReturnType<typeof unitsControllerFindOne>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof unitsControllerFindOne>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUnitsControllerFindOneQueryKey(id);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof unitsControllerFindOne>>> = ({ signal }) =>
		unitsControllerFindOne(id, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof unitsControllerFindOne>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type UnitsControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof unitsControllerFindOne>>>;
export type UnitsControllerFindOneQueryError = ErrorType<UnauthorizedError | InternalServerError>;

/**
 * @summary Find a unit by ID
 */
export const useUnitsControllerFindOne = <
	TData = Awaited<ReturnType<typeof unitsControllerFindOne>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(
	id: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof unitsControllerFindOne>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUnitsControllerFindOneQueryOptions(id, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Update a unit by ID
 */
export const unitsControllerUpdate = (
	id: string,
	updateUnitDto: BodyType<UpdateUnitDto>,
	options?: SecondParameter<typeof customInstance>
) => {
	return customInstance<UpdateUnitResponseDto>(
		{
			url: `/api/v1/units/${id}`,
			method: 'PATCH',
			headers: { 'Content-Type': 'application/json' },
			data: updateUnitDto,
		},
		options
	);
};

export const getUnitsControllerUpdateMutationOptions = <
	TError = ErrorType<BadRequestError | UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof unitsControllerUpdate>>,
		TError,
		{ id: string; data: BodyType<UpdateUnitDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof unitsControllerUpdate>>,
	TError,
	{ id: string; data: BodyType<UpdateUnitDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof unitsControllerUpdate>>,
		{ id: string; data: BodyType<UpdateUnitDto> }
	> = (props) => {
		const { id, data } = props ?? {};

		return unitsControllerUpdate(id, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UnitsControllerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof unitsControllerUpdate>>>;
export type UnitsControllerUpdateMutationBody = BodyType<UpdateUnitDto>;
export type UnitsControllerUpdateMutationError = ErrorType<
	BadRequestError | UnauthorizedError | NotFoundError | InternalServerError
>;

/**
 * @summary Update a unit by ID
 */
export const useUnitsControllerUpdate = <
	TError = ErrorType<BadRequestError | UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof unitsControllerUpdate>>,
		TError,
		{ id: string; data: BodyType<UpdateUnitDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof unitsControllerUpdate>>,
	TError,
	{ id: string; data: BodyType<UpdateUnitDto> },
	TContext
> => {
	const mutationOptions = getUnitsControllerUpdateMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Remove a unit by ID
 */
export const unitsControllerRemove = (id: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<DeleteUnitResponseDto>({ url: `/api/v1/units/${id}`, method: 'DELETE' }, options);
};

export const getUnitsControllerRemoveMutationOptions = <
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof unitsControllerRemove>>, TError, { id: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof unitsControllerRemove>>, TError, { id: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof unitsControllerRemove>>, { id: string }> = (props) => {
		const { id } = props ?? {};

		return unitsControllerRemove(id, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type UnitsControllerRemoveMutationResult = NonNullable<Awaited<ReturnType<typeof unitsControllerRemove>>>;

export type UnitsControllerRemoveMutationError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>;

/**
 * @summary Remove a unit by ID
 */
export const useUnitsControllerRemove = <
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof unitsControllerRemove>>, TError, { id: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof unitsControllerRemove>>, TError, { id: string }, TContext> => {
	const mutationOptions = getUnitsControllerRemoveMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Find all unique tags in a course
 */
export const unitsControllerFindTags = (
	courseId: string,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal
) => {
	return customInstance<FindTagsResponseDto>(
		{ url: `/api/v1/units/tags/${courseId}`, method: 'GET', signal },
		options
	);
};

export const getUnitsControllerFindTagsQueryKey = (courseId: string) => {
	return [`/api/v1/units/tags/${courseId}`] as const;
};

export const getUnitsControllerFindTagsQueryOptions = <
	TData = Awaited<ReturnType<typeof unitsControllerFindTags>>,
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
>(
	courseId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof unitsControllerFindTags>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUnitsControllerFindTagsQueryKey(courseId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof unitsControllerFindTags>>> = ({ signal }) =>
		unitsControllerFindTags(courseId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!courseId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof unitsControllerFindTags>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type UnitsControllerFindTagsQueryResult = NonNullable<Awaited<ReturnType<typeof unitsControllerFindTags>>>;
export type UnitsControllerFindTagsQueryError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>;

/**
 * @summary Find all unique tags in a course
 */
export const useUnitsControllerFindTags = <
	TData = Awaited<ReturnType<typeof unitsControllerFindTags>>,
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
>(
	courseId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof unitsControllerFindTags>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUnitsControllerFindTagsQueryOptions(courseId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
