import { Unit } from '@/api/query/model';
import {
	getUnitsControllerFindAllQueryKey,
	useUnitsControllerRemove,
	useUnitsControllerUpdate,
} from '@/api/query/units/units';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Tag, TagInput } from 'emblor';
import { Label } from '@/components/ui/label';
import StateButton from '@/components/ui/state-button';
import { useToast } from '@/components/ui/use-toast';
import { capitalize, getErrorMessage, unitTypeFromSlug } from '@/lib/utils';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { LucideEdit, LucideTrash } from 'lucide-react';
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { z } from 'zod';

interface EditUnitDialogProps {
	unit: Unit;
	setUnit: (unit: Unit | null) => void;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	hasChildren?: boolean;
	parentUnits?: Pick<Unit, 'title' | 'id'>[];
}

const EditUnitDialogSchema = z.object({
	title: z.string().min(1, "Unit name is required."),
	parentUnit: z.string().min(1, "Parent unit is required."),
	tags: z.array(z.string()).optional(),
});

const DeleteUnitDialogSchema = z.object({
	consequencesCheckbox: z.boolean(),
})

const EditUnitDialog = ({ isOpen, setIsOpen, unit, setUnit, hasChildren, parentUnits }: EditUnitDialogProps) => {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	console.log(parentUnits);

	/* Mutations */
	const { mutate: updateUnit, isPending: isUpdationPending } = useUnitsControllerUpdate();
	const { mutate: deleteUnit, isPending: isDeletionPending } = useUnitsControllerRemove();

	/** Whether the user wishes to delete the unit or edit it. */
	const [shouldDelete, setShouldDelete] = useState(false);
	const action = shouldDelete ? 'Delete' : 'Edit';
	const actionIcon = shouldDelete ? <LucideTrash className="ml-2 h-4 w-4" /> : <LucideEdit className="ml-2 h-4 w-4" />;

	/** Form state for updation. */
	const [unitUpdationData, setUnitUpdationData] = useState<z.infer<typeof EditUnitDialogSchema>>(() => ({
		title: unit.title,
		parentUnit: unit.parentUnit!,
		tags: [], // unit.tags
	}));

	/** Form state for deletion. */
	const [unitDeletionData, setUnitDeletionData] = useState<z.infer<typeof DeleteUnitDialogSchema>>(() => ({
		/* Do you really really want to delete this unit? */
		consequencesCheckbox: false,
	}));

	function clearFormState() {
		setShouldDelete(false);
		setUnitUpdationData({
			title: '',
			parentUnit: '',
			tags: [],
		});
		setUnitDeletionData({
			consequencesCheckbox: false,
		});
	}

	/** Reset the form state when the unit changes. */
	useEffect(() => {
		setUnitUpdationData({
			title: unit.title,
			parentUnit: unit.parentUnit!,
			tags: [], // unit.tags
		});

		setUnitDeletionData({
			consequencesCheckbox: false,
		});

		setShouldDelete(false);
	}, [unit]);

	const [tags, setTags] = useState<Tag[]>([]);
	const [activeTagIndex, setActiveTagIndex] = useState<number | null>(null);

	const removeUnit = () => new Promise((resolve, reject) => { 
		deleteUnit(
			{
				id: unit.id,
			},
			{
				onSuccess: (response) => {
					resolve(response);
				},
				onError: (error) => {
					const errorMessage = error.response?.data.response ?? 'Could not parse the error.';
					reject(new Error(errorMessage));
				},
			}
		);
	});

	const editUnit = () => new Promise((resolve, reject) => {
		const isDirty = unitUpdationData.title !== unit.title || unitUpdationData.parentUnit !== unit.parentUnit;
		if (!isDirty) {
			toast({
				title: 'No changes detected.',
				description: 'No changes were made to the unit.',
				variant: 'destructive',
			});
			return;
		}

		updateUnit(
			{
				id: unit.id,
				data: {
					title:  unitUpdationData.title === unit.title ? undefined : unitUpdationData.title,
					parentUnit: unitUpdationData.parentUnit === unit.parentUnit ? undefined : unitUpdationData.parentUnit,
					// tags: unitUpdationData.tags.length > 0 ? tags.map(tag => tag.text) : undefined,
				},
			},
			{
				onSuccess: (res) => {
					resolve(res)
				},
				onError: (error) => {
					const errorMessage = error.response?.data.response ?? 'Could not parse the error.';
					reject(new Error(errorMessage));
				},
			}
		);
	});

	async function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
		e.preventDefault();

		const validate = shouldDelete ? await DeleteUnitDialogSchema.safeParseAsync(unitDeletionData) : await EditUnitDialogSchema.safeParseAsync(unitUpdationData);
		if (!validate.success) {
			toast({
				title: 'Invalid form data.',
				description: validate.error.errors.map((error) => error.message).join('\n'),
				variant: 'destructive',
			});
			return;
		}

		let itWentWell = false;
		if (shouldDelete) {
			try {
				await removeUnit();
				toast({
					title: 'Unit Deleted',
					description: `'${unit.title}' was deleted successfully.`,
					variant: 'success',
				});
				itWentWell = true;
			} catch (err) {
				toast({
					title: 'Could not delete the unit.',
					description: getErrorMessage(err),
					variant: 'destructive',
				});
			}
		} else {
			try {
				await editUnit();
				toast({
					title: 'Unit Edited',
					description: `'${unitUpdationData.title}' was edited successfully.`,
					variant: 'success',
				});
				itWentWell = true;
			} catch (err) {
				toast({
					title: 'Could not edit the unit.',
					description: getErrorMessage(err),
					variant: 'destructive',
				});
			}
		}

		if (itWentWell) {
			setIsOpen(false);
			clearFormState();
			setUnit(null);
			queryClient.invalidateQueries({
				queryKey: getUnitsControllerFindAllQueryKey({ parentCourse: unit.parentCourse}),
			});
		}
	}

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{action} {`'${unit.title}'`}
					</DialogTitle>
					<DialogDescription>
						{action} this {unit.type}.
					</DialogDescription>
					<form onSubmit={handleSubmit}>
						<div>
							{shouldDelete ? (
								<>
									{hasChildren ? (
										<Label htmlFor="consequencesCheckbox">
											<Checkbox
												name="consequencesCheckbox"
												id="consequencesCheckbox"
												checked={unitDeletionData.consequencesCheckbox}
												onCheckedChange={(e) =>
													setUnitDeletionData((prev) => ({
														...prev,
														consequencesCheckbox: e === 'indeterminate' ? false : e,
													}))
												}
											/>
											<span className="ml-2 text-red-500">
												I understand the consequences of this action.
											</span>
										</Label>
									) : (
										<span>
											Note: This action cannot be done if the {unit.type} has sub-elements.
										</span>
									)}
								</>
							) : (
								<>
									<div className="my-2">
										<Label htmlFor="unitName">{capitalize(unit.type)} Name</Label>
										<Textarea
											value={unitUpdationData.title}
											onChange={(e) => setUnitUpdationData(prev => ({...prev, title: e.target.value }))}
											name="unitName"
											className="my-1"
											placeholder={unit.title}
										></Textarea>
									</div>
									{parentUnits && parentUnits.length > 0 && (
										<div className="my-2">
											<Label htmlFor="parentUnit">
												Parent {capitalize(unitTypeFromSlug(parentUnits[0].id))}
											</Label>
											<Select
												value={unitUpdationData.parentUnit}
												onValueChange={(value) => setUnitUpdationData(prev => ({...prev, parentUnit: value }))}
												name="parentUnit"
											>
												<SelectTrigger>
													<SelectValue placeholder="Change Parent" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectLabel>
															{capitalize(unitTypeFromSlug(parentUnits[0].id)) + 's'}
														</SelectLabel>
														{parentUnits.map((unit) => (
															<SelectItem key={unit.id} value={unit.id}>
																{unit.title}
															</SelectItem>
														))}
													</SelectGroup>
												</SelectContent>
											</Select>
										</div>
									)}
									<div className="my-2 sr-only">
										<Label htmlFor="tags">Tags</Label>
										<TagInput
											placeholder="Add tags"
											name="tags"
											id="tags"
											tags={tags}
											setTags={(newTags) => {
												setTags(newTags);
											}}
											activeTagIndex={activeTagIndex}
											setActiveTagIndex={setActiveTagIndex}
										/>
									</div>
								</>
							)}
						</div>
						<div className="float-right mt-5 gap-2 flex items-center justify-center">
							{/* <Button type="button" variant="outline">
								<LucideClipboardCopy className="mr-2 h-4 w-4" /> Duplicate
							</Button> */}
							<Button
								type="button"
								variant={shouldDelete ? 'iii' : 'destructive'}
								onClick={() => setShouldDelete((prev) => !prev)}
							>
								{shouldDelete ? (
									'No'
								) : (
									<>
										<LucideTrash className="mr-2 h-4 w-4" /> Delete
									</>
								)}
							</Button>
							<StateButton
								type="submit"
								isLoading={isUpdationPending || isDeletionPending}
								isDisabled={isUpdationPending || isDeletionPending} // || (shouldDelete ? !consequencesCheckbox : false)
								variant={shouldDelete ? 'destructive' : 'iii'}
							>
								{action} {capitalize(unit.type)} {actionIcon}
							</StateButton>
						</div>
					</form>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default EditUnitDialog;
