import * as React from 'react';
import { AXIOS_INSTANCE } from '@/api/axios-instance';
import { AuthContext, UserState } from './AuthContextUtils';

function AuthProvider({ children }: Readonly<{ children: React.ReactNode }>) {
	const [user, setUser] = React.useState<UserState>(() => {
		const token = localStorage.getItem('token');
		if (token) {
			console.log('📦 Retrieved token from local storage.');
		}
		return { name: 'guest', token };
	});

	const login = React.useCallback(
		(token: string) => {
			setUser((user) => ({ ...user, token }));
			localStorage.setItem('token', token);
		},
		[setUser]
	);

	const logout = React.useCallback(() => {
		setUser({ name: 'guest', token: null });
		localStorage.removeItem('token');
	}, [setUser]);

	React.useEffect(() => {
		const interceptorId = AXIOS_INSTANCE.interceptors.request.use(
			(config) => {
				if (user.token) {
					config.headers.Authorization = `Bearer ${user.token}`;
				}
				return config;
			}
		);

		return () => {
			AXIOS_INSTANCE.interceptors.request.eject(interceptorId);
		};
	}, [user.token]);

	/* Unauthorized Check */
	// React.useEffect(() => {
	// 	const interceptorId = AXIOS_INSTANCE.interceptors.response.use(undefined, (error) => {
	// 		if (error.response?.status === 401) {
	// 			console.log("🔒 Logging out due to 401.");
	// 			logout();
	// 		}
	// 		return Promise.reject(error);
	// 	})

	// 	return () => {
	// 		AXIOS_INSTANCE.interceptors.response.eject(interceptorId);
	// 	}
	// }, [logout]);

	const memoizedValue = React.useMemo(
		() => ({ user, login, logout }),
		[user, login, logout]
	);

	return (
		<AuthContext.Provider value={memoizedValue}>
			{children}
		</AuthContext.Provider>
	);
}

export default AuthProvider;
