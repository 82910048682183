import {
	contentControllerFindAll,
	getContentControllerFindAllQueryKey,
} from '@/api/query/contents/contents';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
	'/(protected)/_auth/courses/$courseId/$unitId'
)({
	loader: (opts) =>
		opts.context.queryClient.ensureQueryData({
			queryKey: getContentControllerFindAllQueryKey({
				unitId: opts.params.unitId,
			}),
			queryFn: () =>
				contentControllerFindAll({ unitId: opts.params.unitId }),
		}),
	errorComponent: ({ error }) => <div>{error.message}</div>,
});
