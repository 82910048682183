import { create } from 'zustand';

interface UnitTree {
	paperId: string;
	chapterId?: string;
	topicId?: string;
}

interface InsidebarState {
	activeChapter: string;
	setActiveChapter: (chapter: string) => void;
	setActivePaper: (paper: string) => void;
	activePaper: string;
	unitTree: UnitTree;
	setUnitTree: (unitTree: UnitTree) => void;
}

export const useInsidebarToggle = create<InsidebarState>()((set) => ({
	activeChapter: '',
	setActiveChapter: (chapter: string) => {
		set({ activeChapter: chapter });
	},
	activePaper: '',
	setActivePaper: (paper: string) => {
		set({ activePaper: paper });
	},
	unitTree: { paperId: '' } as UnitTree,
	setUnitTree: (unitTree: UnitTree) => {
		set({ unitTree });
	},
}));
