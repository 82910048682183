/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * API Documentation
 * Triple i Curate API Documentation
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/react-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import type { Course, GetAllCoursesResponse, InternalServerError, NotFoundError, UnauthorizedError } from '.././model';
import { customInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Retrieve all courses.
 */
export const coursesControllerFindAll = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<GetAllCoursesResponse>({ url: `/api/v1/courses`, method: 'GET', signal }, options);
};

export const getCoursesControllerFindAllQueryKey = () => {
	return [`/api/v1/courses`] as const;
};

export const getCoursesControllerFindAllQueryOptions = <
	TData = Awaited<ReturnType<typeof coursesControllerFindAll>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof coursesControllerFindAll>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getCoursesControllerFindAllQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof coursesControllerFindAll>>> = ({ signal }) =>
		coursesControllerFindAll(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof coursesControllerFindAll>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type CoursesControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof coursesControllerFindAll>>>;
export type CoursesControllerFindAllQueryError = ErrorType<UnauthorizedError | InternalServerError>;

/**
 * @summary Retrieve all courses.
 */
export const useCoursesControllerFindAll = <
	TData = Awaited<ReturnType<typeof coursesControllerFindAll>>,
	TError = ErrorType<UnauthorizedError | InternalServerError>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof coursesControllerFindAll>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getCoursesControllerFindAllQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieve a course by courseId.
 */
export const coursesControllerFindOne = (
	courseId: string,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal
) => {
	return customInstance<Course>({ url: `/api/v1/courses/${courseId}`, method: 'GET', signal }, options);
};

export const getCoursesControllerFindOneQueryKey = (courseId: string) => {
	return [`/api/v1/courses/${courseId}`] as const;
};

export const getCoursesControllerFindOneQueryOptions = <
	TData = Awaited<ReturnType<typeof coursesControllerFindOne>>,
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
>(
	courseId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof coursesControllerFindOne>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getCoursesControllerFindOneQueryKey(courseId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof coursesControllerFindOne>>> = ({ signal }) =>
		coursesControllerFindOne(courseId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!courseId, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof coursesControllerFindOne>>,
		TError,
		TData
	> & { queryKey: QueryKey };
};

export type CoursesControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof coursesControllerFindOne>>>;
export type CoursesControllerFindOneQueryError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>;

/**
 * @summary Retrieve a course by courseId.
 */
export const useCoursesControllerFindOne = <
	TData = Awaited<ReturnType<typeof coursesControllerFindOne>>,
	TError = ErrorType<UnauthorizedError | NotFoundError | InternalServerError>,
>(
	courseId: string,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof coursesControllerFindOne>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getCoursesControllerFindOneQueryOptions(courseId, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
