import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/')({
  loader: ({ context }) => {
	if (context?.auth?.user.token) {
		console.log("🔓 Token already exists.");
		throw redirect({ to: "/courses"})
	}
  }
})
