import * as React from 'react';
export interface AuthContextType {
	user: UserState;
	login: (token: string) => void;
	logout: () => void;
}

export interface UserState {
	name: string;
	token: string | null;
}

export const AuthContext = React.createContext<AuthContextType | null>(null);
