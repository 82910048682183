import { Unit, UnitType} from '@/api/query/model';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { LucideEdit, LucideFilePlus2, LucideUserRoundCheck } from 'lucide-react';
import CreateUnitDialog from '../_auth/courses/-components/create-unit';
import EditUnitDialog from '../_auth/courses/-components/edit-unit';
import { useState } from 'react';
import AssignUnitDialog from '../_auth/courses/-components/assign-unit';
import { Skeleton } from '@/components/ui/skeleton';

interface SidebarElementProps {
	title: string;
	data?: Unit[];
	isLoading?: boolean;
	activeElementUnitId?: string;
	setActiveElementUnitId: (unitId: string) => void;
	unitType: UnitType;
	parentUnit: string;
	parentCourse: string;
	parentUnits?: Pick<Unit, 'title' | 'id'>[];
}

export function SidebarElement({
	title,
	data,
	isLoading,
	setActiveElementUnitId,
	activeElementUnitId,
	unitType,
	parentUnit,
	parentCourse,
	parentUnits

}: Readonly<SidebarElementProps>) {
	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
	const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
	const [editUnit, setEditUnit] = useState<Unit | null>(null);
	
	const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
	const [assignUnit, setAssignUnit] = useState<Unit | null>(null);

	function handleClick(unitId: string) {
		console.log(unitId);
		setActiveElementUnitId(unitId);
	}
	
	function handleEdit(e: React.SyntheticEvent, unit: Unit) {
		e.stopPropagation();
		console.log("Edit was called: ");
		console.log(unit);
		setEditUnit(unit);
		setIsEditDialogOpen(true);
	}

	function handleAssignment(e: React.SyntheticEvent, unit: Unit) {
		e.stopPropagation();
		setAssignUnit(unit);
		setIsAssignDialogOpen(true);
	}

	function newUnit() {
		setIsCreateDialogOpen(true);
	}

	return (
		<div className="overflow-y-auto shadow-md dark:shadow-zinc-800 py-4 px-2 h-full">
			<Button
				className="transition-transform ease-in-out duration-300 mb-1 translate-x-0 flex items-center gap-2 my-2 text-[1rem] font-bold"
				variant="ghost"
				asChild
			>
				<h1 className="font-bold px-10 text-lg whitespace-nowrap transition-[transform,opacity,display] ease-in-out duration-300 translate-x-0 opacity-100">
					{title}{' '}
					<span className="font-medium">
						{(data?.length ?? 0) > 0 && `(${data?.length})`}
					</span>
				</h1>
			</Button>
			<ScrollArea className="[&>div>div[style]]:!block">
				<nav className="mt-8 h-full w-full">
					<ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px)] lg:min-h-[calc(100vh-32px-40px-32px)] items-start space-y-1">
						{(!data || isLoading) && (
							<>
								<SkeletonButton />
								<SkeletonButton />
								<SkeletonButton />
							</>
						)}
						{data?.map((unit) => (
							<li className="w-full" key={unit.id}>
								<Button
									title={unit.title}
									variant={
										activeElementUnitId === unit.id
											? 'secondary'
											: 'ghost'
									}
									className="w-full group text-start"
									onClick={() => handleClick(unit.id)}
								>
									<span className="w-[30ch] truncate">{unit.title}</span>
									<span title="Edit"><LucideEdit onClick={(e) => handleEdit(e, unit)} className="mr-2 h-4 w-4 invisible group-hover:visible hover:text-blue-500" size={14} /></span>
									<span title="Assign"><LucideUserRoundCheck onClick={(e) => handleAssignment(e, unit)} className="mr-2 h-4 w-4 invisible group-hover:visible hover:text-blue-500" size={14} /></span>
								</Button>
							</li>
						))}
						<li className="w-full">
							<Button
								variant="iii"
								className="w-full"
								onClick={newUnit}
							>
								<LucideFilePlus2
									size={16}
									className="mr-2 h-4 w-4"
								/>{' '}
								<span className="min-w-[30ch]">New {title.slice(0, -1)}</span>
							</Button>
						</li>
					</ul>
				</nav>
			</ScrollArea>
			<CreateUnitDialog
				isOpen={isCreateDialogOpen}
				setIsOpen={setIsCreateDialogOpen}
				parentUnit={parentUnit}
				parentCourse={parentCourse}
				unitType={unitType}
				setActiveElementUnitId={setActiveElementUnitId}
			/>
			{editUnit && (
				<EditUnitDialog
					unit={editUnit}
					setUnit={setEditUnit}
					isOpen={isEditDialogOpen}
					setIsOpen={setIsEditDialogOpen}
					parentUnits={parentUnits}
				/>
			)}
			{assignUnit && (
				<AssignUnitDialog
					unit={assignUnit}
					// setUnit={setAssignUnit}
					isOpen={isAssignDialogOpen}
					setIsOpen={setIsAssignDialogOpen}
				/>
			)}
		</div>
	);
}

function SkeletonButton() {
	return (
		<li className="w-full">
			<Button className="w-full flex gap-2" variant={null}>
				<Skeleton className="w-[20%] h-4" />
				<Skeleton className="w-[40%] h-4" />
				<Skeleton className="w-[40%] h-4" />
			</Button>
		</li>
	)
}
