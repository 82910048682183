import { AuthContextType } from "@/contexts/AuthContextUtils";
import { QueryClient } from "@tanstack/react-query";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";

export interface CustomRouterContext {
    auth: AuthContextType | null;
	queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<CustomRouterContext>()({
	component: () => (
		<Outlet />
	),
});
