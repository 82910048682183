import AdminPanelLayout from '@/components/sidebar/root-layout';

import {
	Outlet,
	createFileRoute,
	redirect,
	useNavigate,
	useParams,
} from '@tanstack/react-router';

import { NavbarBreadcrumb } from './-components/Breadrumb';
import { Sidebar } from './-components/Sidebar';
import { useUsersControllerGetUser } from '@/api/query/users/users';
import { useContext, useEffect } from 'react';
import { AuthContext } from '@/contexts/AuthContextUtils';

export const Route = createFileRoute('/(protected)/_auth')({
	component: AuthenticatedLayout,
	loader: ({ context }) => {
		const token = context.auth?.user.token ?? localStorage.getItem('token');
		if (!token) {
			console.log('🔒 Token does not exist.');
			throw redirect({ to: '/' });
		}
	},
});

function AuthenticatedLayout() {
	const { courseId, unitId } = useParams({ strict: false });
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const { error } = useUsersControllerGetUser({ query: { staleTime: 15000 } });
	useEffect(() => {
		if (error?.response?.status === 401) {
			authContext?.logout();
			navigate({ to: '/' });
		}
	}, [error, authContext, navigate]);

	return (
		<AdminPanelLayout>
			{courseId && <Sidebar />}
			<div className="p-5 flex gap-2">
				{ courseId && <NavbarBreadcrumb courseId={courseId} unitId={unitId} /> }
			</div>
			<hr />
			<div className="p-12 gap-2 w-full max-w-[1920px] mx-auto py-8">
				<Outlet />
			</div>
		</AdminPanelLayout>
	);
}
