import type { HTMLAttributes } from "react";
import humanizeDuration from "humanize-duration";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { LucideCircleX } from "lucide-react";

interface AccessItemProps extends HTMLAttributes<HTMLDivElement> {
	name: string;
	createdAt: Date;
}

export function AccessItem({ name, createdAt, ...props }: Readonly<AccessItemProps>) {

	const timeAgo = `${humanizeDuration(new Date().getTime() - createdAt.getTime(), {largest: 1, round: true})} ago`;

	return (
		<div className="flex justify-between p-2 hover:bg-slate-100 transition-colors rounded-xl mt-1" {...props}>
			<div className="flex gap-2 items-center">
				<div className="bg-red-500 w-6 h-6 rounded-full" />
				<div>
					<p className="font-semibold">{name}</p>
					<p className="text-xs text-gray-500">assigned {timeAgo}.</p>
				</div>
			</div>
			<div className="flex gap-1 items-center">
				<Select name="parentUnit">
					<SelectTrigger className="min-w-[128px]">
						<SelectValue placeholder="Access" />
					</SelectTrigger>
					<SelectContent>
						<SelectGroup>
							<SelectLabel>Access</SelectLabel>
							<SelectItem value="read">View</SelectItem>
							<SelectItem value="delete">Edit</SelectItem>
						</SelectGroup>
					</SelectContent>
				</Select>
				<Button variant="outline" className="group">
					<LucideCircleX className="h-6 w-6 text-gray-600 group-hover:text-red-500 transition-colors" />
				</Button>
			</div>
		</div>
	);
}
